<template>
  <div class="invoice-total-wrapper">
    <div class="invoice-total-item">
      <p class="invoice-total-title">
        {{ $t('invoice.subtotal.excluding_taxes') }} :
      </p>
      <p class="invoice-total-amount">
        {{ servicesSubtotalHT }}{{ $store.state.appConfig.currency }}
      </p>
    </div>
    <div class="invoice-total-item">
      <p class="invoice-total-title">
        {{ $t('invoice.subtotal.taxes') }} :
      </p>
      <p class="invoice-total-amount">
        {{ servicesTotalTVA }}{{ $store.state.appConfig.currency }}
      </p>
    </div>
    <div class="invoice-total-item">
      <p class="invoice-total-title">
        {{ $t('invoice.discount') }} :
      </p>
      <p class="invoice-total-amount">
        {{ servicesDiscount }}{{ $store.state.appConfig.currency }}
      </p>
    </div>
    <hr class="my-50">
    <div class="invoice-total-item">
      <p class="invoice-total-title">
        {{ $t('invoice.total.including_taxes') }} :
      </p>
      <p class="invoice-total-amount">
        {{ servicesTotal }}{{ $store.state.appConfig.currency }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    services: {
      type: Array,
      default() {
        return []
      },
    },
  },
  computed: {
    servicesSubtotalHT() {
      return this.services.map(item => item.HT).reduce((prev, next) => prev + next)
    },
    servicesDiscount() {
      return this.services.map(item => item.discount).reduce((prev, next) => prev + next)
    },
    servicesTotal() {
      return this.servicesSubtotalHT - this.servicesDiscount + this.servicesTotalTVA
    },
    servicesTotalTVA() {
      const totalTTC = this.services.map(item => item.TTC).reduce((prev, next) => prev + next)
      return this.precisionRound((totalTTC - this.servicesSubtotalHT), 2)
    },
  },
  watch: {
    servicesTotal(val) {
      this.$emit('servicesTotal', val)
    },
  },
  methods: {
    precisionRound(number, precision) {
      // eslint-disable-next-line no-restricted-properties
      const factor = Math.pow(10, precision)
      return Math.round(number * factor) / factor
    },
    printQuotation() {
      window.print()
    },
  },
}
</script>

<style lang="scss" scoped>
  @import "~@core/scss/base/pages/app-invoice.scss";
</style>
